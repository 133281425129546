export const baseURL = "https://api.wishgoodwork.com/api/v1";

export const DESCRIPTION_TEXT =
  "WishGoodWork is a workplace appreciation application designed to recognize and celebrate employee achievements within an organization.";

export const SideboxDetails = [
  {
    step: 1,
    title: "Greetings",
  },
  {
    step: 2,
    title: "Greetings Received",
  },
  {
    step: 3,
    title: "Greetings sent by you",
  },
];

export const initialValuesForAddgreet = {
  recipient: "",
  greet: "",
  greetMessage: "",
};

export const initialValuesForOTPScreen = {
  otp_code: "",
};
export const initialValuesForEmail = {
  email: "",
};

export const initialValuesForChangePassword = {
  newpassword: "",
  confirm_newpassword: "",
};

export const initialValuesForUpdatePassword = {
  old_password: "",
  new_password: "",
  confirm_newpassword: "",
};

export const initialValuesForLogin = {
  email: "",
  password: "",
};

export const initialValuesForRegistrationPage = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  password_confirmation: "",
  employee_number: "",
  company_id: "",
  designation: "",
};
