import React, { useEffect, useState } from "react";
import { Typography, Container, useMediaQuery, Box } from "@mui/material";
import homeBg from "../../../assets/images/homeBg.png";
import smallLogo from "../../../assets/logo/small-logo.png";
import mobilehomeBg from "../../../assets/images/mobile-homeBg.png";
import logo from "../../../assets/logo/logo.png";
import { PrimaryButtonURL } from "../../generic-components/button";
import BasicLoader from "../../Loader/BasicLoader";
import { DESCRIPTION_TEXT } from "../../../constant";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const [invalidScreen, setInvalidScreen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const navigate = useNavigate();

  useEffect(() => {
    const userHeaderData = localStorage.getItem("userHeaderData");
    const userData = localStorage.getItem("userData");
    if (userHeaderData && userData) {
      setInvalidScreen(true);
      navigate("/greetlisting");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {invalidScreen ? (
        <BasicLoader />
      ) : (
        <>
          <Box
            className="landingPage"
            style={{
              backgroundImage: `url(${isMobile ? mobilehomeBg : homeBg})`,
            }}
          >
            <Box
              component="img"
              src={smallLogo}
              className="small-logo-bg"
              alt="clecotech-greeting-web-small-logo"
              loading="lazy"
            />
            <Container>
              <Box className="landingPage-content-container">
                <Box className="logo-container center">
                  <Box
                    component="img"
                    src={logo}
                    className="logo"
                    alt="clecotech-greeting-web-logo"
                    loading="lazy"
                  />
                </Box>
                <Typography className="content  mt-10">
                  {DESCRIPTION_TEXT}
                </Typography>
                <PrimaryButtonURL
                  title="Get Started"
                  url="/login"
                  styles="btn rounded-btn mt-30"
                />
              </Box>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

export default LandingPage;
