import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { getUserDataFromLocalStorage } from "../../../services/Utils";

const ProfileHeader = () => {
  const userData = getUserDataFromLocalStorage();
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box className="profile-header-container mt-18">
            <Typography className="profile-heading">
              {`Hello 🙋‍♂️,  ${userData?.first_name} ${userData?.last_name}`}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileHeader;
