/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from "react";
import { Container, Box, Grid, Typography, Stack } from "@mui/material";
import { Formik } from "formik";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { PrimaryButton } from "../../generic-components/button";
import { baseURL, initialValuesForLogin } from "../../../constant";
import SideboxUnregistered from "../../internal-components/sidebox-unregistered";
import InputField from "../../generic-components/input-field";
import { Link, useNavigate } from "react-router-dom";
import { validationForLogin } from "../../../schema";
import axios from "axios";
import PasswordField from "../../generic-components/password-field";
import BasicLoader from "../../Loader/BasicLoader";
import ErrorAlert from "../../generic-components/error-alert/index";
import { getFCM_tokenDataFromLocalStorage } from "../../../services/Utils";

const LoginPage = () => {
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [invalidScreen, setInvalidScreen] = useState(false);

  const navigate = useNavigate();
  const fcm_token = getFCM_tokenDataFromLocalStorage();

  useEffect(() => {
    const userHeaderData = localStorage.getItem("userHeaderData");
    const userData = localStorage.getItem("userData");
    if (userHeaderData && userData) {
      setInvalidScreen(true);
      navigate("/greetlisting");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (values, resetForm) => {
    setIsloading(true);
    try {
      const response = await axios.post(`${baseURL}/users/sign_in`, {
        email: values.email,
        password: values.password,
        fcm_token: fcm_token?.token,
      });

      const { "access-token": accessToken, client, uid } = response.headers;
      const headersObj = { access_token: accessToken, client, uid };
      navigate("/greetlisting");
      resetForm();
      setInvalidLogin(false);
      localStorage.setItem("userData", JSON.stringify(response.data?.data));
      localStorage.setItem("userHeaderData", JSON.stringify(headersObj));
    } catch (error) {
      if (error?.response?.data?.success !== false) {
        resetForm();
        setInvalidLogin(true);
        setErrorMessage("An error occurred while processing your request.");
      } else {
        setInvalidLogin(true);
        setErrorMessage(error?.response?.data?.errors[0]);
      }
    } finally {
      setIsloading(false);
    }
  };

  return (
    <>
      {invalidScreen ? (
        <BasicLoader />
      ) : (
        <>
          <Box className="loginPage">
            <Grid container>
              <Grid item xs={12} md={6}>
                <SideboxUnregistered />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="bg-effect">
                  <Container>
                    <Box className="loginContent">
                      <Box className="content-container">
                        <Typography className="content-title">
                          Hello 👋,
                          <br /> Welcome Back!
                        </Typography>
                        <Typography
                          className="link-content mt-10"
                          sx={{ textAlign: "left !important" }}
                        >
                          Not registered yet with us?{" "}
                          <span>
                            <Link className="redirect-link" to="/register">
                              Register now!
                            </Link>
                          </span>
                        </Typography>
                        <Typography className="content-text mt-18">
                          Stay connected with us by logging in using your
                          credentials.
                        </Typography>
                        <Box className="login-form mt-30">
                          <Formik
                            initialValues={initialValuesForLogin}
                            validationSchema={validationForLogin}
                            onSubmit={(values, { resetForm }) =>
                              login(values, resetForm)
                            }
                          >
                            {({ values, handleSubmit }) => (
                              <form onSubmit={handleSubmit}>
                                <Stack direction="column" spacing={1.5}>
                                  <InputField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="email"
                                    value={values.email}
                                  />

                                  <PasswordField
                                    id="password"
                                    name="password"
                                    label="Password"
                                    value={values.password}
                                  />
                                </Stack>

                                {invalidLogin && errorMessage && (
                                  <ErrorAlert errorMessage={errorMessage} />
                                )}
                                <PrimaryButton
                                  title={
                                    isLoading
                                      ? "Matching Credentials..."
                                      : "Login"
                                  }
                                  styles="submitButton"
                                  isLoading={isLoading}
                                />
                              </form>
                            )}
                          </Formik>
                        </Box>
                        <Stack className="mt-30">
                          <Link className="login_links" to="/forgot-password">
                            <ChevronRightIcon /> Forgot your password?
                          </Link>
                          <Link
                            className="login_links mt-10"
                            to="/confirm-account"
                          >
                            <ChevronRightIcon /> Need assistance confirming your
                            account?
                          </Link>
                          <Link
                            to="/unlock-account"
                            className="mt-10 login_links"
                          >
                            <ChevronRightIcon /> Need assistance unlocking your
                            account?
                          </Link>
                        </Stack>
                      </Box>
                    </Box>
                  </Container>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default LoginPage;
