import React, { useEffect, useState } from "react";
import { Container, Box, Grid, Typography, Stack } from "@mui/material";
import { Formik } from "formik";
import { PrimaryButton } from "../../generic-components/button";
import { initialValuesForChangePassword } from "../../../constant";
import SideboxUnregistered from "../../internal-components/sidebox-unregistered";
import { ValidationforChangePassword } from "../../../schema";
import { useUpdatePasswordMutation } from "../../../services";
import PasswordField from "../../generic-components/password-field";
import BasicLoader from "../../Loader/BasicLoader";
import ErrorAlert from "../../generic-components/error-alert/index";
import { getuserOTPverificationDataFromLocalStorage } from "../../../services/Utils";
import { Link, useNavigate } from "react-router-dom";
import SuccessfullPage from "../successfull-screen";

const ChangePasswordPage = () => {
  const [invalidScreen, setInvalidScreen] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const [updatePassword] = useUpdatePasswordMutation();
  const userOTPverification = getuserOTPverificationDataFromLocalStorage();

  useEffect(() => {
    const userforgotpasswordData = localStorage.getItem(
      "userforgotpasswordData"
    );
    const userOTPVerification = localStorage.getItem("userOTPverification");
    const userHeaderData = localStorage.getItem("userHeaderData");
    const userData = localStorage.getItem("userData");
    if (userHeaderData && userData) {
      setInvalidScreen(true);
      navigate("/greetlisting");
    } else if (!userforgotpasswordData || !userOTPVerification) {
      setInvalidScreen(true);
      navigate("/forgot-password");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatepassword = async (values, resetForm) => {
    setIsloading(true);

    try {
      const params = {
        email: userOTPverification.email,
        password: values.newpassword,
        otp: userOTPverification.otp,
        confirm_password: values.confirm_newpassword,
      };

      const { data, error } = await updatePassword(params);

      if (data) {
        localStorage.clear();
        setUpdated(true);
        resetForm();
        setInvalidOTP(false);
        setSuccessMessage(data?.message);
      } else if (error && error?.data) {
        setInvalidOTP(true);
        setErrorMessage(error?.data?.errors?.confirmation_otp[0]);
      }
    } catch (error) {
      setInvalidOTP(true);
      setErrorMessage("An error occurred while processing your request.");
    } finally {
      setIsloading(false);
    }
  };

  return (
    <>
      {invalidScreen ? (
        <BasicLoader />
      ) : (
        <>
          {updated ? (
            <>
              <SuccessfullPage successMessage={successMessage} />
            </>
          ) : (
            <>
              <Box className="loginPage">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <SideboxUnregistered />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="bg-effect">
                      <Container>
                        <Box className="loginContent">
                          <Box className="content-container">
                            <Typography className="content-title">
                              Hello 👋,
                              <br /> Want to change password?
                            </Typography>
                            <Typography className="content-text mt-10">
                              Interested in updating your password? <br /> Once
                              you've completed this process, you'll be able to
                              log in to your account using the newly updated
                              password.
                            </Typography>
                            <Box className="login-form mt-30">
                              <Formik
                                initialValues={initialValuesForChangePassword}
                                validationSchema={ValidationforChangePassword}
                                onSubmit={(values, { resetForm }) =>
                                  updatepassword(values, resetForm)
                                }
                              >
                                {({ values, handleSubmit }) => (
                                  <form onSubmit={handleSubmit}>
                                    <Stack direction="column" spacing={1.5}>
                                      <PasswordField
                                        id="newpassword"
                                        name="newpassword"
                                        label="New Password"
                                        value={values.newpassword}
                                      />

                                      <PasswordField
                                        id="confirm_newpassword"
                                        name="confirm_newpassword"
                                        label="Confirm New Password"
                                        value={values.confirm_newpassword}
                                      />
                                    </Stack>
                                    {invalidOTP && !updated && (
                                      <ErrorAlert errorMessage={errorMessage} />
                                    )}
                                    <PrimaryButton
                                      title={
                                        isLoading
                                          ? "Updating..."
                                          : "Update Password"
                                      }
                                      styles="submitButton"
                                    />
                                    <Typography className="link-content mt-50">
                                      Would you like to log in?{" "}
                                      <span>
                                        <Link
                                          className="redirect-link"
                                          to="/login"
                                        >
                                          Login
                                        </Link>
                                      </span>
                                    </Typography>
                                  </form>
                                )}
                              </Formik>
                            </Box>
                          </Box>
                        </Box>
                      </Container>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ChangePasswordPage;
