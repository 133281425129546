import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";

const InputSelect = ({ id, name, label, value, itemList,blur }) => {
  const { handleChange, handleBlur, touched, errors } = useFormikContext();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <>
      {" "}
      <FormControl for={id} fullWidth error={touched[name] && !!errors[name]}>
        <InputLabel
          aria-labelledby={name}
          id={id}
          for={id}
          error={touched[name] && !!errors[name]}
        >
          {label}
        </InputLabel>
        <Select
          labelId={id}
          id={id}
          name={name}
          value={value}
          label={label}
          onChange={handleChange}
          onBlur={() => {
            handleBlur();
            blur();
        }}
        
          error={touched[name] && !!errors[name]}
          MenuProps={MenuProps}
          aria-labelledby={id}
        >
          {!itemList || itemList?.length === 0 ? (
            <MenuItem>
              <small> No Data found !</small>
            </MenuItem>
          ) : (
            itemList?.map((details, index) => (
              <MenuItem key={index} value={details?.id}>
                {details?.name ||
                  `${details?.first_name} ${details?.last_name}`}
                {details?.designation && (
                  <span style={{ fontSize: "12px", marginLeft: "10px" }}>
                    ({details?.designation})
                  </span>
                )}
              </MenuItem>
            ))
          )}
        </Select>
        {touched[name] && errors[name] && (
          <FormHelperText>{errors[name]}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};
InputSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  itemList: PropTypes.array,
  blur: PropTypes.func,
};

export default InputSelect;
