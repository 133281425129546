import React, { useEffect, useState } from "react";
import { Container, Box, Grid, Typography, Button } from "@mui/material";
import { Formik } from "formik";
import SideboxUnregistered from "../../internal-components/sidebox-unregistered";
import InputField from "../../generic-components/input-field";
import { ValidationforOTPpage } from "../../../schema/index";
import { PrimaryButton } from "../../generic-components/button/index";
import { initialValuesForOTPScreen } from "../../../constant/index";
import {
  useLazyOTPverificationForgotPasswordQuery,
  useResetPasswordMutation,
} from "../../../services";
import { Link, useNavigate } from "react-router-dom";
import BasicLoader from "../../Loader/BasicLoader";
import ErrorAlert from "../../generic-components/error-alert/index";
import useToast from "../../../hooks/useToast";
import { getuserForgotPasswordDataFromLocalStorage } from "../../../services/Utils";

const ForgotPasswordOtpPage = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [invalidScreen, setInvalidScreen] = useState(false);
  const [resendOTPLoading, setResendOTPLoading] = useState(false);
  const { showErrorToast, showSuccessToast } = useToast();

  const [OTPverification, { isError, isLoading }] =
    useLazyOTPverificationForgotPasswordQuery();

  const navigate = useNavigate();

  const [resetPasswordLink] = useResetPasswordMutation();

  useEffect(() => {
    const userforgotpasswordData = localStorage.getItem(
      "userforgotpasswordData"
    );
    const userHeaderData = localStorage.getItem("userHeaderData");
    const userData = localStorage.getItem("userData");
    if (userHeaderData && userData) {
      setInvalidScreen(true);
      navigate("/greetlisting");
    } else if (!userforgotpasswordData) {
      setInvalidScreen(true);
      navigate("/forgot-password");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userForgotpassworddata = getuserForgotPasswordDataFromLocalStorage();

  const resendOTP = async () => {
    setResendOTPLoading(true);
    const params = {
      email: userForgotpassworddata.email,
    };
    try {
      const response = await resetPasswordLink(params);
      setResendOTPLoading(false);
      showSuccessToast(response.data.message);
    } catch (err) {
      setResendOTPLoading(false);
      showErrorToast("OTP not sent !");
    }
  };

  const verfiyOTP = async (values, resetForm) => {
    const params = {
      otp: values.otp_code,
      email: encodeURIComponent(userForgotpassworddata.email),
    };
    const { isSuccess, error } = await OTPverification(params);

    if (isSuccess) {
      localStorage.setItem(
        "userOTPverification",
        JSON.stringify({
          email: userForgotpassworddata.email,
          otp: values.otp_code,
        })
      );
      navigate("/reset-password");
      resetForm();
    } else {
      setErrorMessage(error.data.errors.confirmation_otp[0]);
    }
  };

  return (
    <>
      {invalidScreen ? (
        <BasicLoader />
      ) : (
        <>
          <Box className="loginPage">
            <Grid container>
              <Grid item xs={12} md={6}>
                <SideboxUnregistered />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="bg-effect">
                  <Container>
                    <Box className="loginContent">
                      <Box className="content-container">
                        <Typography className="content-title">
                          Hello 👋
                        </Typography>
                        <Typography className="content-text mt-10">
                          Please proceed with the OTP we've sent to your
                          registered email to reset your password.
                        </Typography>
                        <Box className="login-form mt-30">
                          <Formik
                            initialValues={initialValuesForOTPScreen}
                            validationSchema={ValidationforOTPpage}
                            onSubmit={(values, { resetForm }) =>
                              verfiyOTP(values, resetForm)
                            }
                          >
                            {({ values, handleSubmit }) => (
                              <form onSubmit={handleSubmit}>
                                <InputField
                                  id="otp_code"
                                  name="otp_code"
                                  label="OTP Code"
                                  value={values.otp_code}
                                />

                                {isError && (
                                  <ErrorAlert
                                    errorMessage={
                                      errorMessage
                                        ? errorMessage
                                        : "Invalid OTP or expired."
                                    }
                                  />
                                )}
                                <PrimaryButton
                                  title={
                                    isLoading ? "Verifying..." : "Verify OTP"
                                  }
                                  styles="submitButton"
                                />
                              </form>
                            )}
                          </Formik>
                          <Box className="mt-30 resend-otp-container">
                            <Button onClick={resendOTP} className="resendOTP">
                              {resendOTPLoading ? "Resending..." : "Resend OTP"}
                            </Button>
                          </Box>
                          <Typography className="link-content mt-50">
                            Would you like to log in?{" "}
                            <span>
                              <Link className="redirect-link" to="/login">
                                Login
                              </Link>
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Container>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default ForgotPasswordOtpPage;
