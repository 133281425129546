import { Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const PrimaryButtonIcon = ({
  title,
  styles,
  startIcon,
  handleClick,
  withContent = false,
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <Button className={styles} startIcon={startIcon} onClick={handleClick}>
      {!withContent ? (
        !isMobile && <Typography className="ml-5">{title}</Typography>
      ) : (
        <Typography className="ml-5">{title}</Typography>
      )}
    </Button>
  );
};

export const PrimaryButton = ({ title, styles }) => {
  return (
    <Button type="submit" className={`${styles} mt-30`} fullWidth>
      {title}
    </Button>
  );
};

export const PrimaryButtonURL = ({ title, styles, url }) => {
  return (
    <Button component={Link} to={url} className={styles} fullWidth>
      {title}
    </Button>
  );
};
