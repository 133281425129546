import { Box, Typography, Container, Button } from "@mui/material";
import React from "react";
import WifiOffRoundedIcon from "@mui/icons-material/WifiOffRounded";
const NoInternetPage = () => {
  const styles = {
    root: {
      textAlign: "center",
      color: "#000",
    },
  };
  return (
    <Box className="no_internet" style={styles.root}>
      <Container maxWidth="lg">
        <Box className="no-internet-container">
          <Box className="icon" style={styles.iconBox}>
            <WifiOffRoundedIcon />
          </Box>
          <Typography variant="h5" className="mt-30" style={styles.heading}>
            <strong>You're Disconnected!</strong>
          </Typography>
          <Typography
            variant="body1"
            className="mt-18"
            style={styles.description}
            sx={{ color: "#757575" }}
          >
            It seems your internet connection has gone down. Please resolve this
            issue before proceeding with<strong>WishGoodWorks</strong>
          </Typography>
          <Button
            className="submitButton mt-18"
            onClick={() => window.location.reload()}
            sx={{ minWidth: "240px" }}
          >
            Retry
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default NoInternetPage;
