import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../constant";
import {
  getHeaderDataFromLocalStorage,
  getUserDataFromLocalStorage,
} from "./Utils";

export const GreetAPI = createApi({
  reducerPath: "GreetAPI",
  baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
  prepareHeaders: async (headers) => {
    const response = await getHeaderDataFromLocalStorage();
    var token = response?.access_token;
    if (token) {
      headers.set("Content-Type", "application/json");
    }
    return headers;
  },
  endpoints: (builder) => ({
    getOrgansationDetails: builder.query({
      query: () => ({
        url: "/companies",
        method: "GET",
      }),
    }),
    getGreetList: builder.query({
      query: () => ({
        url: "/greeting_categories",
        method: "GET",
      }),
    }),
    getRecipientList: builder.query({
      query: () => {
        const userData = getUserDataFromLocalStorage();
        const headerData = getHeaderDataFromLocalStorage();
        return {
          url: `/companies/${userData?.company_id}/users`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            uid: headerData?.uid,
            "access-token": headerData?.access_token,
            client: headerData?.client,
          },
        };
      },
    }),
    OTPverification: builder.query({
      query: (params) => ({
        url: `users/confirmation?confirmation_otp=${params?.otp}&email=${params?.email}`,
        method: "GET",
      }),
    }),
    OTPverificationUnlockAccount: builder.query({
      query: (params) => ({
        url: `users/unlock?confirmation_otp=${params?.otp}&email=${params?.email}`,
        method: "GET",
      }),
    }),
    OTPverificationForgotPassword: builder.query({
      query: (params) => ({
        url: `users/password/edit?confirmation_otp=${params?.otp}&email=${params?.email}`,
        method: "GET",
      }),
    }),
    SentGreet: builder.mutation({
      query: ({ recipient, greetMessage, greet }) => {
        const userData = getUserDataFromLocalStorage();
        const headerData = getHeaderDataFromLocalStorage();
        return {
          url: "/greetings",
          method: "POST",
          body: {
            sender_id: userData?.id,
            receiver_id: recipient,
            content: greetMessage,
            greeting_category_id: greet,
            company_id: userData?.company_id,
          },
          headers: {
            "Content-Type": "application/json",
            uid: headerData?.uid,
            "access-token": headerData?.access_token,
            client: headerData?.client,
          },
        };
      },
    }),
    Registration: builder.mutation({
      query: ({
        email,
        password,
        passwordConfirmation,
        first_name,
        last_name,
        employee_number,
        company_id,
        designation,
      }) => ({
        url: "/users",
        method: "POST",
        body: {
          email: email,
          password: password,
          password_confirmation: passwordConfirmation,
          first_name: first_name,
          last_name: last_name,
          employee_number: employee_number,
          company_id: company_id,
          designation: designation,
        },
      }),
    }),
    ResetPassword: builder.mutation({
      query: ({ email }) => ({
        url: "/users/password",
        method: "POST",
        body: {
          email: email,
          redirect_url: "",
        },
      }),
    }),
    UnlockAccount: builder.mutation({
      query: ({ email }) => ({
        url: "/users/unlock",
        method: "POST",
        body: {
          email: email,
          redirect_url: "",
        },
      }),
    }),

    Logout: builder.query({
      query: () => {
        const headerData = getHeaderDataFromLocalStorage();
        return {
          url: "/users/sign_out",
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            uid: headerData?.uid,
            "access-token": headerData?.access_token,
            client: headerData?.client,
          },
        };
      },
    }),
    UpdatePassword: builder.mutation({
      query: ({ password, confirm_password, email, otp }) => ({
        url: "/users/password",
        method: "PATCH",
        body: {
          email: email,
          password: password,
          otp: otp,
          confirm_password: confirm_password,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    SetPassword: builder.mutation({
      
      query: ({ password, invitation_token, user_id, password_confirmation }) => ({
        url: "/users/invitation",
        method: "PUT",
        body: {user: {
          invitation_token: invitation_token,
          password: password,
          user_id: user_id,
          password_confirmation: password_confirmation,
        }},
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    ConfirmAccount: builder.mutation({
      query: ({ email }) => ({
        url: "/users/confirmation",
        method: "POST",
        body: {
          email: email,
        },
      }),
    }),
    UpdateNewPassword: builder.mutation({
      query: ({ oldPassword, newPassword, confirm_newPassword }) => {
        const userData = getUserDataFromLocalStorage();
        const headerData = getHeaderDataFromLocalStorage();
        return {
          url: `/companies/${userData?.company_id}/users/update_password`,
          method: "PATCH",
          body: {
            old_password: oldPassword,
            password: newPassword,
            password_confirmation: confirm_newPassword,
           
          },
          headers: {
            "Content-Type": "application/json",
            uid: headerData?.uid,
            "access-token": headerData?.access_token,
            client: headerData?.client,
          },
        };
      },
    }),
  }),
});

export const {
  useLazyGetOrgansationDetailsQuery,
  useLazyGetGreetListQuery,
  useLazyGetRecipientListQuery,
  useLazyOTPverificationQuery,
  useLazyOTPverificationForgotPasswordQuery,
  useLazyOTPverificationUnlockAccountQuery,
  useLazyLogoutQuery,
  useSentGreetMutation,
  useRegistrationMutation,
  useResetPasswordMutation,
  useUnlockAccountMutation,
  useUpdatePasswordMutation,
  useSetPasswordMutation,
  useConfirmAccountMutation,
  useUpdateNewPasswordMutation,
} = GreetAPI;
