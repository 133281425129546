import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import SharedHeader from "../../generic-components/shared-header";
import GreetListingLoader from "../../Loader/GreetListingLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { baseURL } from "../../../constant";
import GreetDialog from "../greet-dialog";
import {
  getHeaderDataFromLocalStorage,
  getUserDataFromLocalStorage,
} from "../../../services/Utils";

const GreetSentList = () => {
  const [sentGreetList, setSentGreetList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limitReached, setLimitReached] = useState(false);
  const [page, setPage] = useState(1);

  const { company_id, id } = getUserDataFromLocalStorage();
  const headerData = getHeaderDataFromLocalStorage();

  useEffect(() => {
    fetchSentGreetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const fetchSentGreetList = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/companies/${company_id}/greetings?page=${page}&sender_id=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            uid: headerData.uid,
            "access-token": headerData.access_token,
            client: headerData.client,
          },
        }
      );
      const data = response?.data;
      if (data.length < 5) {
        setLimitReached(true);
      }
      setSentGreetList((prevData) => [...prevData, ...data]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const renderGreetDialogs = () => {
    return sentGreetList.map((greeting, index) => (
      <Box key={index}>
        <GreetDialog greetingInfo={greeting} />
      </Box>
    ));
  };

  const renderEmptyMessage = () => (
    <Typography
      className="mt-50"
      style={{ textAlign: "center", color: "#555555" }}
    >
      <strong>Oops 😟! No data was found...</strong>
    </Typography>
  );

  return (
    <>
      <SharedHeader title="Greetings sent by you" />
      <Box>
        {isLoading ? (
          <GreetListingLoader />
        ) : (
          <>
            {sentGreetList.length !== 0 ? (
              <>
                {renderGreetDialogs()}
                <InfiniteScroll
                  dataLength={sentGreetList.length}
                  next={fetchMoreData}
                  hasMore={!limitReached}
                  loader={<GreetListingLoader />}
                  endMessage={
                    <Typography
                      className="mt-50"
                      style={{ textAlign: "center", color: "#555555" }}
                    >
                      <b>😃 You've seen everything!</b>
                    </Typography>
                  }
                />
              </>
            ) : (
              renderEmptyMessage()
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default GreetSentList;
