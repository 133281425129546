import { Box } from "@mui/material";
import React from "react";
import loginBg from "../../../assets/images/loginBg.png";
import smallLogo from "../../../assets/logo/small-logo.png";

const SideboxUnregistered = () => {
  return (
    <>
      <Box
        className="loginSideBox center"
        sx={{ backgroundImage: `url(${loginBg})` }}
      >
        <Box className="logo-container center">
          <Box
            component="img"
            src={smallLogo}
            className="logo"
            alt="clecotech-greeting-web-logo"
            loading="lazy"
          />
        </Box>
      </Box>
    </>
  );
};

export default SideboxUnregistered;
