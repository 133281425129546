import { Box, useMediaQuery, Typography, Avatar } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SideboxDetails } from "../../../constant";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { getUserDataFromLocalStorage } from "../../../services/Utils";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `.0625rem solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: ".875rem", color: "#fff" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  background:
    "transparent linear-gradient(90deg, #033B92 0%, #082755 100%) 0% 0% no-repeat padding-box;",
  color: "#fff",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}));

const GreetSidebox = ({ tabSections, handleButtonClick }) => {
  const isMobile = useMediaQuery("(max-width:37.5rem)");
  const userData = getUserDataFromLocalStorage();

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const loggedinUserName = `${userData?.first_name} ${userData?.last_name}`;

  return (
    <>
      {!isMobile ? (
        <>
          <Box className="greet-sidebox">
            <Box className="sidebox-img-container mt-50">
              <Avatar
                alt={loggedinUserName}
                src={userData?.profile_image}
                className="profileImg"
                loading="lazy"
              />
            </Box>
            <Box className="sidebox-text mt-18">{loggedinUserName}</Box>

            <Box className="sidebox-button-container mt-30">
              {SideboxDetails.map((button, index) => (
                <Box
                  key={index}
                  onClick={() => {
                    handleScrollToTop();
                    handleButtonClick(button.step - 1);
                  }}
                  className={classNames(
                    "sidebox-button mt-10 animation_bounceToLeft",
                    {
                      activeButton: tabSections === button.step - 1,
                    }
                  )}
                >
                  {button.title}
                </Box>
              ))}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Accordion expanded={expanded} onChange={handleExpand}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>Menu</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="greet-sidebox">
                <Box className="sidebox-img-container mt-18">
                  <Avatar
                    alt={loggedinUserName}
                    src={userData?.profile_image}
                    className="profileImg"
                    loading="lazy"
                  />
                </Box>
                <Box className="sidebox-button-container mt-18">
                  {SideboxDetails.map((button, index) => (
                    <Box
                      key={index}
                      onClick={() => {
                        handleScrollToTop();
                        handleButtonClick(button.step - 1);
                        handleExpand();
                      }}
                      className={classNames(
                        "sidebox-button mt-10 animation_bounceToLeft",
                        {
                          activeButton: tabSections === button.step - 1,
                        }
                      )}
                    >
                      {button.title}
                    </Box>
                  ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </>
  );
};

GreetSidebox.propTypes = {
  handleButtonClick: PropTypes.func,
  tabSections: PropTypes.number,
};

export default GreetSidebox;
