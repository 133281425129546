import { useSelector } from "react-redux";

const useGetOrganisation = (id) => {
  const { OrganisationDetails, isLoading } = useSelector(
    (state) => state.organisation
  );
  const organisation = OrganisationDetails?.find((item) => item?.id === id);
  return { organisation, isLoading };
};

export default useGetOrganisation;
