import {
  Box,
  Container,
  Stack,
  Grid,
  Typography,
  Alert,
  AlertTitle,
  List,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import GreetHeader from "../../layout/header/greet-header";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import GreetFooter from "../../layout/greet-footer";
import {
  PrimaryButton,
  PrimaryButtonIcon,
} from "../../generic-components/button";
import { ValidationforUpdatePassword } from "../../../schema";
import BasicLoader from "../../Loader/BasicLoader";
import SharedHeader from "../../generic-components/shared-header";
import ProfileHeader from "../../generic-components/profile-header";
import { initialValuesForUpdatePassword } from "../../../constant";
import PasswordField from "../../generic-components/password-field";
import { useUpdateNewPasswordMutation } from "../../../services";
import ErrorAlert from "./../../generic-components/error-alert/index";
import SuccessAlert from "./../../generic-components/success-alert/index";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const UpdatePassword = () => {
  const [invalidScreen, setInvalidScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setErrorState] = useState({
    hasError: false,
    errorMessage: "",
  });
  const [successState, setSuccessState] = useState({
    hasSuccess: false,
    successMessage: "",
  });

  useEffect(() => {
    const userHeaderData = localStorage.getItem("userHeaderData");
    const userData = localStorage.getItem("userData");
    if (!userHeaderData || !userData) {
      setInvalidScreen(true);
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const [UpdateNewPassword] = useUpdateNewPasswordMutation();

  const handleSuccess = (SuccessMessage) => {
    setIsLoading(false);
    setSuccessState({
      hasSuccess: true,
      successMessage: `${SuccessMessage}  <br /> We will redirect you to login screen in 2 seconds!`,
    });
    clearLocalStorage();
    setErrorState({
      hasError: false,
    });
  };

  const handleError = (errorMessage) => {
    setIsLoading(false);
    setErrorState({
      hasError: true,
      errorMessage: errorMessage,
    });
    setSuccessState({
      hasSuccess: false,
    });
  };

  const handleUnexpectedError = () => {
    setIsLoading(false);
    setErrorState({
      hasError: true,
      errorMessage: "An unexpected error occurred while changing the password.",
    });
    setSuccessState({
      hasSuccess: false,
    });
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("userHeaderData");
  };

  const handleChangePassword = async (values, resetForm) => {
    setIsLoading(true);

    try {
      const response = await UpdateNewPassword({
        oldPassword: values.old_password,
        newPassword: values.new_password,
        confirmNewPassword: values.confirm_newpassword,
      });

      if (response?.data) {
        handleSuccess(response?.data?.message);
        resetForm();
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else if (response?.error) {
        handleError(response?.error?.data?.errors[0]);
      }
    } catch (error) {
      handleUnexpectedError();
      throw error;
    }
  };
  const handleBack = () => window.history.back();

  return (
    <>
      {invalidScreen ? (
        <>
          <BasicLoader />
        </>
      ) : (
        <>
          <GreetHeader />
          <Box className="profile-section mb-30">
            <Container>
              <PrimaryButtonIcon
                handleClick={handleBack}
                title="Back"
                styles="Back-btn mt-10"
                startIcon={<ArrowBackIosIcon className="back-icon" />}
                withContent={true}
              />
              <ProfileHeader />
              <Grid container>
                <Grid item xs={12}>
                  <Box className="changePassword-section-container mt-18">
                    <SharedHeader title="Update your Password" />
                    <Box className="changePassword-container">
                      <Alert severity="info" className="mt-18">
                        <AlertTitle>After completing this process:</AlertTitle>
                        <List>
                          <Typography className="content">
                            {" "}
                            Access your account seamlessly with your updated
                            password.
                          </Typography>
                          <Typography className="mt-10 content">
                            {" "}
                            You will be redirected to the login page in 2
                            seconds.
                          </Typography>
                          <Typography className="mt-10 content">
                            Ensure security by logging out from all devices.
                          </Typography>
                        </List>
                      </Alert>

                      <Formik
                        initialValues={initialValuesForUpdatePassword}
                        validationSchema={ValidationforUpdatePassword}
                        onSubmit={(values, { resetForm }) =>
                          handleChangePassword(values, resetForm)
                        }
                      >
                        {({ values, handleSubmit }) => (
                          <form onSubmit={handleSubmit}>
                            <Grid container>
                              <Grid item xs={12} sm={8} md={10}>
                                <Box className="field-container mt-30">
                                  <Typography className="field-heading mb-18">
                                    Enter your old password
                                  </Typography>
                                  <Stack className="mt-18">
                                    <PasswordField
                                      id="old_password"
                                      name="old_password"
                                      label="Old password"
                                      value={values.old_password}
                                    />
                                  </Stack>
                                  <Stack className="mt-18">
                                    <Typography className="field-heading mb-18">
                                      Enter your new password
                                    </Typography>

                                    <PasswordField
                                      id="newpassword"
                                      name="new_password"
                                      label="New password"
                                      value={values.new_password}
                                    />
                                  </Stack>
                                  <Stack className="mt-18">
                                    <PasswordField
                                      id="confirm_newpassword"
                                      name="confirm_newpassword"
                                      label="Confirm new password"
                                      value={values.confirm_newpassword}
                                    />
                                  </Stack>
                                  {errorState.hasError && (
                                    <ErrorAlert
                                      errorMessage={errorState.errorMessage}
                                    />
                                  )}
                                  {successState.hasSuccess && (
                                    <SuccessAlert
                                      SuccessMessage={
                                        successState.successMessage
                                      }
                                    />
                                  )}
                                </Box>

                                <PrimaryButton
                                  title={
                                    isLoading
                                      ? "Updating..."
                                      : "Update Password"
                                  }
                                  styles="primarybtn"
                                />
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      </Formik>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <GreetFooter />
        </>
      )}
    </>
  );
};
export default UpdatePassword;
