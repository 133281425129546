import React, { useEffect, useState } from "react";
import { Container, Box, Grid, Typography, Stack, Alert } from "@mui/material";
import { Formik } from "formik";
import { PrimaryButton } from "../../generic-components/button";
import SideboxUnregistered from "../../internal-components/sidebox-unregistered";
import InputField from "../../generic-components/input-field";
import { ValidationforRegistrationPage } from "../../../schema";
import { initialValuesForRegistrationPage } from "./../../../constant/index";
import { Link, useNavigate } from "react-router-dom";
import {
  useRegistrationMutation,
} from "../../../services";
import PasswordField from "../../generic-components/password-field";
import InputSelect from "./../../generic-components/form-select-input/index";
import { useSelector } from "react-redux";
import BasicLoader from "../../Loader/BasicLoader";
import ErrorAlert from "../../generic-components/error-alert/index";

const RegistrationPage = () => {
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [validLogin, setValidLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [invalidScreen, setInvalidScreen] = useState(false);
  const navigate = useNavigate();

  const [sentRegistrationData] = useRegistrationMutation();

  
  const Organisations = useSelector((state) => state.organisation);

  useEffect(() => {
    const userHeaderData = localStorage.getItem("userHeaderData");
    const userData = localStorage.getItem("userData");
    if (userHeaderData && userData) {
      setInvalidScreen(true);
      navigate("/greetlisting");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const register = async (values, resetForm) => {
    setIsloading(true);
    const response = await sentRegistrationData({
      email: values.email,
      password: values.password,
      password_confirmation: values.passwordConfirmation,
      first_name: values.first_name,
      last_name: values.last_name,
      employee_number: values.employee_number,
      company_id: values.company_id,
      designation: values.designation,
    });

    if (response.data) {
      setIsloading(false);
      setValidLogin(true);
      setInvalidLogin(false);
      resetForm();
      setSuccessMessage(response.data.message);
      localStorage.setItem(
        "userRegisterdData",
        JSON.stringify({
          email: values.email,
        })
      );
      setTimeout(() => {
        navigate("/verify-otp");
      }, 2000);
    } else {
      setIsloading(false);
      setInvalidLogin(true);
      setErrorMessage(response.error.data.errors.full_messages[0]);
    }
  };
  return (
    <>
      {invalidScreen ? (
        <BasicLoader />
      ) : (
        <>
          <Box className="loginPage">
            <Grid container>
              <Grid item xs={12} md={6}>
                <SideboxUnregistered />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="bg-effect">
                  <Container>
                    <Box className="loginContent">
                      <Box className="content-container">
                        {validLogin && !invalidLogin ? (
                          <Alert severity="success" className="mt-18">
                            {successMessage} <br />
                            <br />
                            We will rediect you to OTP screen in few Seconds !
                          </Alert>
                        ) : (
                          <>
                            <Typography className="content-title">
                              Hello 👋,
                              <br /> Welcome!
                            </Typography>
                            <Typography
                              className="link-content mt-10"
                              sx={{ textAlign: "left !important" }}
                            >
                              Already registered with us?{" "}
                              <span>
                                <Link className="redirect-link" to="/login">
                                  Login
                                </Link>
                              </span>
                            </Typography>
                            <Typography className="content-text mt-18">
                              Register yourself to WishGoodWork!
                            </Typography>
                            <Box className="login-form mt-30">
                              <Formik
                                initialValues={initialValuesForRegistrationPage}
                                validationSchema={ValidationforRegistrationPage}
                                onSubmit={(values, { resetForm }) =>
                                  register(values, resetForm)
                                }
                              >
                                {({ values, handleSubmit }) => (
                                  <form onSubmit={handleSubmit}>
                                    <Stack className="mt-10">
                                      <InputSelect
                                        value={values.company_id}
                                        id="company_id"
                                        name="company_id"
                                        label="Organisation"
                                        itemList={
                                          Organisations?.OrganisationDetails
                                        }
                                      />
                                    </Stack>

                                    <Stack
                                      direction={{ xs: "column", md: "row" }}
                                      spacing={1}
                                      className="mt-10"
                                    >
                                      <InputField
                                        id="first_name"
                                        name="first_name"
                                        label="First Name"
                                        type="text"
                                        value={values.first_name}
                                      />

                                      <InputField
                                        id="last_name"
                                        name="last_name"
                                        label="Last Name"
                                        type="text"
                                        value={values.last_name}
                                      />
                                    </Stack>

                                    <Stack className="mt-10">
                                      <InputField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        type="email"
                                        value={values.email}
                                      />
                                    </Stack>

                                    <Stack
                                      direction={{ xs: "column", md: "row" }}
                                      spacing={1}
                                      className="mt-10"
                                    >
                                      <PasswordField
                                        id="password"
                                        name="password"
                                        label="Password"
                                        value={values.password}
                                      />

                                      <PasswordField
                                        id="password_confirmation"
                                        name="password_confirmation"
                                        label="Confirm Password"
                                        value={values.password_confirmation}
                                      />
                                    </Stack>

                                    <Stack
                                      direction={{ xs: "column", md: "row" }}
                                      spacing={1}
                                      className="mt-10"
                                    >
                                      <InputField
                                        id="employee_number"
                                        name="employee_number"
                                        label="Employee Id"
                                        type="text"
                                        value={values.employee_number}
                                      />
                                      <InputField
                                        id="designation"
                                        name="designation"
                                        label="Designation"
                                        type="text"
                                        value={values.designation}
                                      />
                                    </Stack>
                                    {invalidLogin && !validLogin && (
                                      <ErrorAlert errorMessage={errorMessage} />
                                    )}

                                    <PrimaryButton
                                      title={
                                        isLoading
                                          ? "Registering..."
                                          : "Register"
                                      }
                                      styles="submitButton"
                                    />
                                  </form>
                                )}
                              </Formik>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Container>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default RegistrationPage;
