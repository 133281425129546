import {
  Avatar,
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment-timezone";

const GreetCard = ({ onOpen, greetingInfo }) => {
  const { greeting_category, content, sender, receiver, created_at } =
    greetingInfo;

  const isMobile = useMediaQuery("(max-width:600px)");

  const senderName = `${sender?.first_name} ${sender?.last_name}`;
  const receiverName = `${receiver.first_name} ${receiver.last_name}`;

  return (
    <Box className="greet-card mt-18" onClick={onOpen}>
      <Grid container spacing={1}>
        <>
          <Grid item xs={12} sm={3} md={2}>
            <Box className="greet-icon-container">
              <Box className="greet-icon center">
                <Box
                  component="img"
                  src={greeting_category.icon}
                  className="icon"
                  alt={`cleco-greet-${greeting_category.name}`}
                  loading="lazy"
                />
              </Box>
              {isMobile && (
                <Box>
                  <Typography className="greet-heading">
                    {greeting_category.name}
                  </Typography>
                  <Typography className="greet-name">{receiverName}</Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            {!isMobile && (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className="heading-date-container"
              >
                <Box>
                  <Typography className="greet-heading">
                    {greeting_category.name}
                  </Typography>
                  <Typography className="greet-name">{receiverName}</Typography>
                </Box>
                <Box className="sender-date-tag">
                  <Typography className="sender-date">
                    {moment(created_at).format("MMMM Do, YYYY")}
                  </Typography>
                </Box>
              </Stack>
            )}
            <Typography className="greet-message mt-10">{content}</Typography>
            <Box>
              <Box className="senderInfo-container mt-18">
                {isMobile && (
                  <Box className="sender-date-tag">
                    <Typography className="sender-date">
                      {moment(created_at).format("MMMM Do, YYYY")}
                    </Typography>
                  </Box>
                )}

                <Box className="sender-info-tag center">
                  <Box className="sender-info-name-tag center mr-10">
                    <Avatar
                      className="avatar"
                      alt={senderName}
                      src={sender?.profile_image}
                    />
                  </Box>
                  <Box className="sender-info-container">
                    <Typography className="sender-info-heading">
                      from
                    </Typography>
                    <Typography className="sender-info">
                      {senderName}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </>
      </Grid>
    </Box>
  );
};

GreetCard.propTypes = {
  greetingInfo: PropTypes.object,
  onOpen: PropTypes.func,
};

export default GreetCard;
