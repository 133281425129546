import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  Slide,
  Grid,
  Container,
  TextField,
  Typography,
  DialogContentText,
  FormHelperText,
  FormControl,
} from "@mui/material";
import {
  PrimaryButton,
  PrimaryButtonIcon,
} from "../../generic-components/button";
import { Formik } from "formik";
import InputSelect from "../../generic-components/form-select-input";
import { initialValuesForAddgreet } from "../../../constant";
import { ValidationforAddGreet } from "../../../schema";
import {
  useLazyGetGreetListQuery,
  useLazyGetRecipientListQuery,
  useSentGreetMutation,
} from "../../../services";
import { getUserDataFromLocalStorage } from "../../../services/Utils";
import useToast from "../../../hooks/useToast";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddGreetDialog = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const [greetList, setGreetList] = useState([]);
  const [recipientList, setRecipientList] = useState([]);

  const userData = getUserDataFromLocalStorage();

  const [sentGreetData] = useSentGreetMutation();
  const [fetchGreetList] = useLazyGetGreetListQuery();
  const [fetchRecipientList] = useLazyGetRecipientListQuery();

  const { showErrorToast, showSuccessToast } = useToast();

  const fetchGreetListData = async () => {
    const { data } = await fetchGreetList();
    setGreetList(data);
  };
  const fetchRecipientListData = async () => {
    const { data } = await fetchRecipientList();
    setRecipientList(data);
  };

  useEffect(() => {
    fetchGreetListData();
    fetchRecipientListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialog = () => {
    setOpen(!open);
  };

  const sentGreet = async (values, resetForm) => {
    setIsloading(true);
    try {
      const { data } = await sentGreetData({
        recipient: values.recipient,
        greet: values.greet,
        greetMessage: values.greetMessage,
      });

      if (data) {
        handleDialog();
        resetForm();
        showSuccessToast(data?.message);
      }
    } catch (error) {
      showErrorToast(error?.data?.errors[0]);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <Box
      className="addGreetdialog"
      sx={{
        position: "fixed",
        bottom: { xs: "6%", sm: "12%" },
        right: "5%",
        zIndex: "1",
      }}
    >
      <PrimaryButtonIcon
        handleClick={handleDialog}
        title="Add Greeting"
        styles="addGreetButton"
        startIcon={<AddCircleOutlineIcon className="add-circle" />}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialog}
        aria-describedby="alert-dialog-slide-description"
        className="dialogBox"
      >
        <Container>
          <>
            <DialogContentText className="description mt-18">
              Hello, {`${userData?.first_name} ${userData?.last_name}`} !
            </DialogContentText>
            <Typography className="dialogTitle">
              Send your greeting to your colleague..
            </Typography>
            <Formik
              initialValues={initialValuesForAddgreet}
              validationSchema={ValidationforAddGreet}
              onSubmit={(values, { resetForm }) => sentGreet(values, resetForm)}
            >
              {({
                values,
                handleSubmit,
                touched,
                errors,
                handleChange,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit} className="mt-30 mb-30">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputSelect
                        id="recipient"
                        value={values.recipient}
                        name="recipient"
                        label="Select Colleague"
                        itemList={recipientList}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputSelect
                        id="greet"
                        value={values.greet}
                        name="greet"
                        label="Category"
                        itemList={greetList}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        error={touched.greetMessage && !!errors.greetMessage}
                      >
                        <TextField
                          name="greetMessage"
                          id="greet-message"
                          label="Greeting Message"
                          value={values.greetMessage}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          multiline
                          fullWidth
                          rows={6}
                          error={touched.greetMessage && !!errors.greetMessage}
                          aria-labelledby="greet-message"
                        />
                        {touched.greetMessage && !!errors.greetMessage && (
                          <FormHelperText>{errors.greetMessage}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      <PrimaryButton
                        title={isLoading ? "Sending..." : "Send Greeting"}
                        styles="submitButton"
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </>
        </Container>
      </Dialog>
    </Box>
  );
};

export default AddGreetDialog;
