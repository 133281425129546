import React, { useState, useEffect } from "react";
import { Box, Container, Grid } from "@mui/material";
import GreetListing from "../../internal-components/greet-listing";
import GreetSidebox from "../../internal-components/greet-sidebox";
import SentList from "../../internal-components/greet-sentList";
import RecievedList from "../../internal-components/greet-recievedlist";
import AddGreetDialog from "../../internal-components/greet-add-dialog";
import { useSelector } from "react-redux";
import BasicLoader from "../../Loader/BasicLoader";
import GreetHeader from "../../layout/header/greet-header";
import { useNavigate } from "react-router-dom";
import GreetFooter from "../../layout/greet-footer";

const GreetPage = () => {
  const [tabSections, setTabSections] = useState(0);
  const { greetUpdated } = useSelector((state) => state.greeting);
  const [invalidScreen, setInvalidScreen] = useState(false);

  const handleButtonClick = (selectedSection) => {
    setTabSections(selectedSection);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const userHeaderData = localStorage.getItem("userHeaderData");
    const userData = localStorage.getItem("userData");
    if (!userHeaderData || !userData) {
      setInvalidScreen(true);
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (greetUpdated) {
      setTabSections(0);
    }
  }, [greetUpdated]);

  const renderProfileSection = () => {
    switch (tabSections) {
      case 0:
        return <GreetListing />;
      case 1:
        return <RecievedList />;
      case 2:
        return <SentList />;
      default:
        return null;
    }
  };

  return (
    <>
      {invalidScreen ? (
        <>
          <BasicLoader />
        </>
      ) : (
        <>
          <GreetHeader />
          <Box className="greetPage">
            <AddGreetDialog />
            <Container>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <GreetSidebox
                    tabSections={tabSections}
                    handleButtonClick={handleButtonClick}
                  />
                </Grid>

                <Grid item xs={12} sm={8}>
                  <Box className="Tab-box">{renderProfileSection()}</Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <GreetFooter />
        </>
      )}
    </>
  );
};

export default GreetPage;
