import React, { useState } from "react";
import { PrimaryButtonIcon } from "../../generic-components/button";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  DialogTitle,
  Button,
  Slide,
} from "@mui/material";
import { useLazyLogoutQuery } from "../../../services";
import useToast from "../../../hooks/useToast";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LogoutDialog = () => {
  const [open, setOpen] = useState(false);

  const { showErrorToast } = useToast();
  const [logout] = useLazyLogoutQuery();

  const handleDialog = () => {
    setOpen(!open);
  };

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await logout();
      const { isSuccess, isError, data, error } = response;

      if (isSuccess && data && data.success) {
        localStorage.removeItem("userData");
        localStorage.removeItem("userHeaderData");
        navigate("/login");
      } else if (isError && error && error.data && !error.data.success) {
        showErrorToast(error.data.errors[0]);
      }
    } catch (error) {
      showErrorToast("An error occurred while logging out.");
    }
  };

  return (
    <Box>
      <PrimaryButtonIcon
        handleClick={handleDialog}
        title="Logout"
        styles="logoutButton"
        startIcon={<LogoutIcon className="logout-icon" />}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialog}
        aria-describedby="alert-dialog-slide-description"
        className="dialogBox"
      >
        <DialogTitle className="dialogTitle">Confirm Logout</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className="dialoge-button outlined-dialoge-button"
            onClick={handleDialog}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="dialoge-button filled-dialoge-button"
            onClick={handleLogout}
            color="primary"
          >
            Yes, Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LogoutDialog;
