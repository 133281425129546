import React, { useEffect, useState } from "react";
import { Container, Box, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import SideboxUnregistered from "../../internal-components/sidebox-unregistered";
import InputField from "../../generic-components/input-field";
import { ValidationforEmail } from "./../../../schema/index";
import { PrimaryButton } from "./../../generic-components/button/index";
import { initialValuesForEmail } from "./../../../constant/index";
import { Link, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../../../services";
import BasicLoader from "../../Loader/BasicLoader";
import ErrorAlert from "../../generic-components/error-alert/index";

const ForgotPasswordPage = () => {
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [invalidScreen, setInvalidScreen] = useState(false);

  const navigate = useNavigate();

  const [resetPasswordLink] = useResetPasswordMutation();

  useEffect(() => {
    const userHeaderData = localStorage.getItem("userHeaderData");
    const userData = localStorage.getItem("userData");
    if (userHeaderData && userData) {
      setInvalidScreen(true);
      navigate("/greetlisting");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sentLink = async (values, resetForm) => {
    setIsloading(true);

    try {
      const params = {
        email: values.email,
      };
      const { data, error } = await resetPasswordLink(params);

      if (data) {
        setInvalidOTP(false);
        setInvalidOTP(false);
        localStorage.setItem(
          "userforgotpasswordData",
          JSON.stringify({
            email: values.email,
          })
        );
        navigate("/forgot-password-otp");
        resetForm();
      } else if (error) {
        setInvalidOTP(true);
        setErrorMessage(error?.data?.errors[0]);
      }
    } catch (error) {
      setInvalidOTP(true);
      setErrorMessage("An error occurred while processing your request.");
    } finally {
      setIsloading(false);
    }
  };

  return (
    <>
      {invalidScreen ? (
        <BasicLoader />
      ) : (
        <>
          <Box className="loginPage">
            <Grid container>
              <Grid item xs={12} md={6}>
                <SideboxUnregistered />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="bg-effect">
                  <Container>
                    <Box className="loginContent">
                      <Box className="content-container">
                        <Typography className="content-title">
                          Oops 😒,
                          <br /> Forgot your password?
                        </Typography>
                        <Typography className="content-text mt-10">
                          Please provide your email address to receive a link
                          for resetting your password.
                        </Typography>
                        <Box className="login-form mt-30">
                          <Formik
                            initialValues={initialValuesForEmail}
                            validationSchema={ValidationforEmail}
                            onSubmit={(values, { resetForm }) =>
                              sentLink(values, resetForm)
                            }
                          >
                            {({ values, handleSubmit }) => (
                              <form onSubmit={handleSubmit}>
                                <InputField
                                  id="Email"
                                  name="email"
                                  label="Email"
                                  type="email"
                                  value={values.email}
                                />

                                {invalidOTP && (
                                  <ErrorAlert errorMessage={errorMessage} />
                                )}
                                <PrimaryButton
                                  title={
                                    isLoading ? "Sending..." : "Send Reset Link"
                                  }
                                  styles="submitButton"
                                />
                                <Typography className="link-content mt-50">
                                  Would you like to log in?{" "}
                                  <span>
                                    <Link className="redirect-link" to="/login">
                                      Login
                                    </Link>
                                  </span>
                                </Typography>
                              </form>
                            )}
                          </Formik>
                        </Box>
                      </Box>
                    </Box>
                  </Container>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default ForgotPasswordPage;
