import { Box } from "@mui/material";
import React from "react";

const BasicLoader = () => {
  return (
    <Box className="basicLoader">
      <Box className="spinner" />
    </Box>
  );
};

export default BasicLoader;
