import { createSlice } from "@reduxjs/toolkit";
import { GreetAPI } from "../../../services";


const OrganisationSlice = createSlice({
  name: "organisation",
  initialState:{
    OrganisationDetails:[],
    isLoading:true
},
  reducers: {},
  extraReducers: (builder) => {
      builder.addMatcher(GreetAPI.endpoints.getOrgansationDetails.matchFulfilled, (state, { payload }) => {
        state.OrganisationDetails = payload;
        state.isLoading=false;
      });
      builder.addMatcher(GreetAPI.endpoints.getOrgansationDetails.matchRejected, (state) => {
        state.isLoading=false;
      });
    },
});

export default OrganisationSlice.reducer;
