import { createSlice } from "@reduxjs/toolkit";
import { GreetAPI } from "../../../services";


const GreetSlice = createSlice({
  name: "greeting",
  initialState :{
    greetUpdated: false,
  },
  reducers: { 
    onUpdate : (state,{payload}) => {
      state.greetUpdated = payload;
  }
},
  extraReducers: (builder) => {

    builder.addMatcher(GreetAPI.endpoints.SentGreet.matchFulfilled, (state ) => {
      state.greetUpdated = true;
    });

    // handle pending

    builder.addMatcher(GreetAPI.endpoints.SentGreet.matchPending, (state ) => {
      state.greetUpdated = !state.greetUpdated;
    });

    //rejection handling

    builder.addMatcher(GreetAPI.endpoints.SentGreet.matchRejected, (state ) => {
      state.greetUpdated = !state.greetUpdated;
    });
    
  },
});

export const {onUpdate} = GreetSlice.actions;
export default GreetSlice.reducer;
