import axios from "axios";
import { baseURL } from './../constant/index';
import { getHeaderDataFromLocalStorage, getUserDataFromLocalStorage } from "./Utils";

export const setFCM_token = async ({ token, email }) => {
    const userData = getUserDataFromLocalStorage();
    const headerData = getHeaderDataFromLocalStorage();
  
    const body = {
      fcm_token: token,
      email: email,
    };
  
    const headers = {
      "Content-Type": "application/json",
      uid: headerData?.uid,
      "access-token": headerData?.access_token,
      client: headerData?.client,
    };
  
    try {
      const response = await axios.post(
        `${baseURL}/companies/${userData?.company_id}/users/set_fcm_token`,
        body,
        { headers }
      );
      return response;
    } catch (error) {
      return error.message;
    }
  };
  