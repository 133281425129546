import React, { useState, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LandingPage from "./components/screens/landing-page";
import GreetPage from "./components/screens/greet-page";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FullPageLoader from "./components/Loader/FullPageLoader";
import NoInternetPage from "./components/screens/no-internet";
import { HelmetProvider, Helmet } from "react-helmet-async";
import OTPPage from "./components/screens/otp-page";
import LoginPage from "./components/screens/login-page";
import RegistrationPage from "./components/screens/registration-page";
import ForgotPasswordPage from "./components/screens/forgot-password-page";
import UnlockAccountPage from "./components/screens/unlock-account";
import { UseScrollTop } from "./hooks/useScrolltoTop";
import ChangePasswordPage from "./components/screens/change-password";
import ForgotPasswordOtpPage from "./components/screens/forgotpassword-otp-page";
import UnclockAccountOtpPage from "./components/screens/unlock-otp-screen";
import ConfirmAccountPage from "./components/screens/confirm-account";
import ConfirmAccountOTPPage from "./components/screens/confirm-account-otp-page";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import firebase from "./firebase";
import ProfileSection from "./components/screens/profile";
import { getUserDataFromLocalStorage } from "./services/Utils";
import { setFCM_token } from "./services/Api";
import { useLazyGetOrgansationDetailsQuery } from "./services";
import UpdatePassword from "./components/screens/UpdatePassword";
import SetPassword from "./components/screens/set-password";
const App = () => {
  const renderTitle = () => {
    const pathname = window.location.pathname;
    switch (pathname) {
      case "/":
        return "WishGoodWork - Elevate Your Colleague Greeting Experience";
      case "/login":
        return "WishGoodWork - Login ✌";
      case "/greetlisting":
        return "WishGoodWork - Greet List 👏";
      case "/accounts":
        return "WishGoodWork - Profile 🙋‍♂️";
      default:
        return "WishGoodWork - Elevate Your Colleague Greeting Experience";
    }
  };

  const [onLoad, setonLoad] = useState(true);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const messaging = getMessaging(firebase);
  const WEB_PUSH_TOKEN = process.env.WEB_PUSH_TOKEN;
  const userData = getUserDataFromLocalStorage();

  const [getOrgansationDetails] = useLazyGetOrgansationDetailsQuery();

  useEffect(() => {
    getOrgansationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeFirebaseMessaging = async () => {
    const token = await getToken(messaging, {
      vapidKey: WEB_PUSH_TOKEN,
    });

    if (token) {
      const userDatainfo = localStorage.getItem("userData");

      if (userDatainfo && Notification.permission === "granted") {
        const params = {
          email: userData?.email,
          token: token,
        };
        setFCM_token(params);
      } else {
        localStorage.setItem(
          "FCM_TOKEN",
          JSON.stringify({
            token: token,
          })
        );
      }

      onMessage(messaging, (payload) => {
        const messageTitle = payload.notification.title;
        const messageBody = payload.notification.body;
        if (Notification.permission === "granted") {
          new Notification(messageTitle, {
            body: messageBody,
          });
        }
      });
    } else {
      console.log("An error occurred while retrieving token.");
    }
  };

  useEffect(() => {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        initializeFirebaseMessaging();
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission()
          .then(async (permission) => {
            if (permission === "granted") {
              await initializeFirebaseMessaging();
              window.location.reload(true);
            }
          })
          .catch((error) => {
            console.error("Error requesting notification permission:", error);
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setonLoad(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{renderTitle()}</title>
        </Helmet>

        {!isOnline ? (
          <NoInternetPage />
        ) : onLoad ? (
          <FullPageLoader />
        ) : (
          <>
            <UseScrollTop />
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegistrationPage />} />
              <Route path="/verify-otp" element={<OTPPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route
                path="/forgot-password-otp"
                element={<ForgotPasswordOtpPage />}
              />
              <Route path="/reset-password" element={<ChangePasswordPage />} />
              <Route path="/unlock-account" element={<UnlockAccountPage />} />
              <Route
                path="/unlock-account-otp"
                element={<UnclockAccountOtpPage />}
              />
              <Route path="/confirm-account" element={<ConfirmAccountPage />} />
              <Route
                path="/confirm-account-otp"
                element={<ConfirmAccountOTPPage />}
              />
              <Route path="/greetlisting" element={<GreetPage />} />
              <Route path="/accounts" element={<ProfileSection />} />
              <Route path="/updatePassword" element={<UpdatePassword />} />
              <Route path="/setPassword" element={<SetPassword />} />

              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <ToastContainer />
          </>
        )}
      </HelmetProvider>
    </>
  );
};

export default App;
