import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Typography,
  Slide,
  Avatar,
} from "@mui/material";
import PropTypes from "prop-types";
import GreetCard from "../../generic-components/greet-card";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GreetDialogContent = ({ greetingInfo }) => {
  const { greeting_category, content, sender, receiver } = greetingInfo;

  const senderName = `${sender?.first_name} ${sender?.last_name}`;
  const receiverName = `${receiver.first_name} ${receiver.last_name}`;

  return (
    <>
      <Box className="dialog-header mb-18">
        <Box className="greet-icon-container mr-10">
          <Box className="greet-icon center">
            <Box
              component="img"
              src={greeting_category?.icon}
              className="icon"
              alt={`cleco-greet-${greeting_category?.name}`}
              loading="lazy"
            />
          </Box>
        </Box>
        <Box className="dialog-content">
          <Typography variant="heading" className="dialog-heading">
            {greeting_category.name}
          </Typography>
          <Typography className="dialog-subheading">{receiverName}</Typography>
        </Box>
      </Box>
      <Divider />
      <DialogContent>
        <DialogContentText className="dialog-description text-center">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box className="sender-info-tag center">
          <Box className="sender-info-name-tag center mr-10">
            <Avatar
              className="avatar"
              alt={senderName}
              src={sender?.profile_image}
            />
          </Box>
          <Box className="sender-info-container">
            <Typography className="sender-info-heading">from</Typography>
            <Typography className="sender-info">{senderName}</Typography>
          </Box>
        </Box>
      </DialogActions>
    </>
  );
};

const GreetDialog = ({ greetingInfo }) => {
  const [open, setOpen] = useState(false);

  const handleDialog = () => {
    setOpen(!open);
  };

  return (
    <Box>
      <GreetCard onOpen={handleDialog} greetingInfo={greetingInfo} />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialog}
        aria-describedby="alert-dialog-slide-description"
        className="dialogBox"
      >
        <GreetDialogContent greetingInfo={greetingInfo} />
      </Dialog>
    </Box>
  );
};

GreetDialog.propTypes = {
  greetingInfo: PropTypes.object,
};

export default GreetDialog;
