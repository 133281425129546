import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Avatar, MenuList, Stack, Typography } from "@mui/material";
// import smallLogo from "../../../assets/logo/small-logo.png";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { NavLink } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { getUserDataFromLocalStorage } from "../../../services/Utils";

export default function Menu() {
  const [state, setState] = React.useState({
    Left: false,
  });

  const anchor = "left";

  const userData = getUserDataFromLocalStorage();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box className="drawer-container">
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end"
        className="drawer-background"
      >
        <CloseOutlinedIcon
          onClick={toggleDrawer(anchor, false)}
          className="cross-icon"
        />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Box className="sidebox-img-container">
            <Avatar
              alt={`${userData?.first_name} - Profile image`}
              src={userData?.profile_image}
              className="profileImg"
              loading="lazy"
            />
          </Box>

          <Box className="user-info">
            <Typography className="userHeading">{`${userData?.first_name} ${userData?.last_name}`}</Typography>
            <Typography className="userSubheading">{`${userData?.designation}`}</Typography>
          </Box>
        </Stack>
      </Stack>
      <Box className="drawer-links">
        <Stack direction="column" spacing={{ sm: 3, md: 4 }}>
          <NavLink to="/greetlisting" className="nav-link-small mt-5">
            <HomeOutlinedIcon className="mr-10 icon" /> Greetings
          </NavLink>
          <NavLink to="/accounts" className="nav-link-small mt-5">
            <ManageAccountsOutlinedIcon className="mr-10 icon" />
            Account
          </NavLink>
        </Stack>
      </Box>
    </Box>
  );

  return (
    <>
      <Box>
        <MenuList className="menu-icon" onClick={toggleDrawer(anchor, true)}>
          <MenuOutlinedIcon className="icon" />
        </MenuList>
        <Drawer
          PaperProps={{
            sx: { width: "100%" },
          }}
          anchor={anchor}
          open={state[anchor]}
        >
          {list(anchor)}
        </Drawer>
      </Box>
    </>
  );
}
