import React from "react";
import { Alert } from "@mui/material";

const ErrorAlert = ({ errorMessage }) => {
  return (
    <>
      <Alert severity="error" className="mt-18">
        {errorMessage}
      </Alert>
    </>
  );
};

export default ErrorAlert;
