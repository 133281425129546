export const getHeaderDataFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("userHeaderData"));
};

export const getUserDataFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("userData"));
};

export const getuserOTPverificationDataFromLocalStorage = () => {
  const data = localStorage.getItem("userOTPverification");
  return JSON.parse(data);
};

export const getuserConfirmAccountDataFromLocalStorage = () => {
  const data = localStorage.getItem("userConfirmAccountData");
  return JSON.parse(data);
};

export   const getuserForgotPasswordDataFromLocalStorage = () => {
  const data = localStorage.getItem("userforgotpasswordData");
  return JSON.parse(data);
};

export   const getuserRegisteredDataFromLocalStorage = () => {
  const data = localStorage.getItem("userRegisterdData");
  return JSON.parse(data);
};

export   const getuserUnlockAccountDataFromLocalStorage = () => {
  const data = localStorage.getItem("userUnlockAccountData");
  return JSON.parse(data);
};

export   const getFCM_tokenDataFromLocalStorage = () => {
  const data = localStorage.getItem("FCM_TOKEN");
  return JSON.parse(data);
};