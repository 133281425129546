import React, { useEffect, useState } from "react";
import { Container, Box, Grid, Typography, Stack } from "@mui/material";
import { Formik } from "formik";
import { PrimaryButton } from "../../generic-components/button";
import { initialValuesForChangePassword } from "../../../constant";
import SideboxUnregistered from "../../internal-components/sidebox-unregistered";
import { ValidationforChangePassword } from "../../../schema";
import { useSetPasswordMutation } from "../../../services";
import PasswordField from "../../generic-components/password-field";
import BasicLoader from "../../Loader/BasicLoader";
import ErrorAlert from "../../generic-components/error-alert/index";
import { Link, useNavigate } from "react-router-dom";
import SuccessfullPage from "../successfull-screen";

const SetPasswordPage = () => {
  const [invalidScreen, setInvalidScreen] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [userInfo, setUserInfo] = useState({
    invitation_token: "",
    user_id: "",
  });

  const navigate = useNavigate();

  const [setPassword] = useSetPasswordMutation();

  useEffect(() => {
    const userHeaderData = localStorage.getItem("userHeaderData");
    const userData = localStorage.getItem("userData");
    if (userHeaderData && userData) {
      setInvalidScreen(true);
      navigate("/greetlisting");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      const invitationToken = urlParams.get("invitation_token");
      const userId = urlParams.get("user");

      if (!invitationToken || !userId) {
        throw new Error('Missing URL parameters');
      }

      setUserInfo({
        invitation_token:invitationToken,
        user_id:userId,
      });
    } catch (error) {
      console.error('Error parsing URL parameters:', error.message);
    }
  }, [])

  const setpassword = async (values, resetForm) => {
    setIsloading(true);

    try {
      const params = {
        invitation_token: userInfo?.invitation_token,
        password: values?.newpassword,
        user_id: userInfo?.user_id,
        password_confirmation: values?.confirm_newpassword,
      };

      const { data, error } = await setPassword(params);
    
      if (data) {
        setUpdated(true);
        resetForm();
        setInvalidOTP(false);
        setSuccessMessage(data?.message);
      } else if (error && error?.data) {
        setInvalidOTP(true);
        setErrorMessage(error?.data?.errors?.full_message[0] || "An error occurred while processing your request." );
      }
    } catch (error) {
      setInvalidOTP(true);
      setErrorMessage("An error occurred while processing your request.");
    } finally {
      setIsloading(false);
    }
  };

  return (
    <>
      {invalidScreen ? (
        <BasicLoader />
      ) : (
        <>
          {updated ? (
            <>
              <SuccessfullPage successMessage={successMessage} />
            </>
          ) : (
            <>
              <Box className="loginPage">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <SideboxUnregistered />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="bg-effect">
                      <Container>
                        <Box className="loginContent">
                          <Box className="content-container">
                            <Typography className="content-title">
                              Hello 👋,
                              <br /> Want to set password?
                            </Typography>
                            <Typography className="content-text mt-10">
                              Interested in set your password? <br /> Once
                              you've completed this process, you'll be able to
                              log in to your account using the newly updated
                              password.
                            </Typography>
                            <Box className="login-form mt-30">
                              <Formik
                                initialValues={initialValuesForChangePassword}
                                validationSchema={ValidationforChangePassword}
                                onSubmit={(values, { resetForm }) =>
                                  setpassword(values, resetForm)
                                }
                              >
                                {({ values, handleSubmit }) => (
                                  <form onSubmit={handleSubmit}>
                                    <Stack direction="column" spacing={1.5}>
                                      <PasswordField
                                        id="newpassword"
                                        name="newpassword"
                                        label="New Password"
                                        value={values.newpassword}
                                      />

                                      <PasswordField
                                        id="confirm_newpassword"
                                        name="confirm_newpassword"
                                        label="Confirm New Password"
                                        value={values.confirm_newpassword}
                                      />
                                    </Stack>
                                    {invalidOTP && !updated && (
                                      <ErrorAlert errorMessage={errorMessage} />
                                    )}
                                    <PrimaryButton
                                      title={
                                        isLoading
                                          ? "Please wait..."
                                          : "Set Password"
                                      }
                                      styles="submitButton"
                                    />
                                    <Typography className="link-content mt-50">
                                      Would you like to log in?{" "}
                                      <span>
                                        <Link
                                          className="redirect-link"
                                          to="/login"
                                        >
                                          Login
                                        </Link>
                                      </span>
                                    </Typography>
                                  </form>
                                )}
                              </Formik>
                            </Box>
                          </Box>
                        </Box>
                      </Container>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SetPasswordPage;
