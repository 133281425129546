/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LogoutDialog from "../../../internal-components/logout-dialog";
import smallLogo from "../../../../assets/logo/small-logo.png";
import useGetOrganisation from "../../../../hooks/useGetOrganisation";
import { getUserDataFromLocalStorage } from "../../../../services/Utils";
import { NavLink } from "react-router-dom";
import Menu from "../../../generic-components/menu/Menu";

const GreetHeader = () => {
  const userData = getUserDataFromLocalStorage();

  const { isLoading, organisation } = useGetOrganisation(userData?.company_id);

  const organisationName = !isLoading ? organisation?.name : "... 🤔";

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Box className="header center">
        <Container>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="header-container"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              {isMobile && <Menu />}
              <Box className="header-logo center">
                <Box
                  component="img"
                  className="logo"
                  src={smallLogo}
                  alt="clecotech-greet-web-small-logo"
                  loading="lazy"
                />
              </Box>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={{ sm: 3, md: 4 }}
            >
              {!isMobile && (
                <>
                  <Stack direction="row" spacing={{ sm: 3, md: 4 }}>
                    <NavLink to="/greetlisting" className="nav-link">
                      Greetings
                    </NavLink>
                    <NavLink to="/accounts" className="nav-link">
                      Account
                    </NavLink>
                  </Stack>
                </>
              )}
              <LogoutDialog />
            </Stack>
          </Stack>
        </Container>
      </Box>

      <Box className="organisation-header center">
        <Container>
          <Box className="organisationDetail-container">
            <Box className="organisation-name">
              <Box className="side-line mr-10" />
              <Typography className="organisationInfo">
                {organisationName}
              </Typography>
            </Box>
            <Box className="organisation-logo">
              {organisation?.company_logo && (
                <>
                  <Box
                    component="img"
                    className="logo"
                    src={organisation?.company_logo}
                    alt="clecotech-greet-web-large-logo"
                    loading="lazy"
                  />
                </>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default GreetHeader;
