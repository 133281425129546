import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

const GreetListingLoader = () => {
  return (
    <>
      <Box className="greetlistingLoader mt-18">
        <Box sx={{ width: { xs: "40%", sm: "30%", md: "20%" } }}>
          <Skeleton variant="circular" width="100px" height="100px" />
        </Box>
        <Box sx={{ width: { xs: "60%", sm: "70%", md: "80%" } }}>
          <Skeleton variant="rounded" width="60%" height="20px" />
          <Skeleton
            variant="rounded"
            width="40%"
            height="20px"
            className="mt-10"
          />
          <Skeleton
            variant="rounded"
            width="100%"
            height="20px"
            className="mt-10"
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            className="mt-18"
          >
            <Skeleton variant="rounded" width="30%" height="40px" />
            <Skeleton variant="rounded" width="30%" height="40px" />
          </Stack>
        </Box>
      </Box>

      <Box className="greetlistingLoader mt-18">
        <Box sx={{ width: { xs: "40%", sm: "30%", md: "20%" } }}>
          <Skeleton variant="circular" width="100px" height="100px" />
        </Box>
        <Box sx={{ width: { xs: "60%", sm: "70%", md: "80%" } }}>
          <Skeleton variant="rounded" width="60%" height="20px" />
          <Skeleton
            variant="rounded"
            width="40%"
            height="20px"
            className="mt-10"
          />
          <Skeleton
            variant="rounded"
            width="100%"
            height="20px"
            className="mt-10"
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            className="mt-18"
          >
            <Skeleton variant="rounded" width="30%" height="40px" />
            <Skeleton variant="rounded" width="30%" height="40px" />
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default GreetListingLoader;
