import React, { useEffect, useState } from "react";
import { Container, Box, Grid, Typography, Button } from "@mui/material";
import { Formik } from "formik";
import SideboxUnregistered from "../../internal-components/sidebox-unregistered";
import InputField from "../../generic-components/input-field";
import { ValidationforOTPpage } from "./../../../schema/index";
import { PrimaryButton } from "./../../generic-components/button/index";
import { initialValuesForOTPScreen } from "./../../../constant/index";
import {
  useLazyOTPverificationQuery,
  useConfirmAccountMutation,
} from "../../../services";
import BasicLoader from "./../../Loader/BasicLoader";
import useToast from "../../../hooks/useToast";
import ErrorAlert from "../../generic-components/error-alert/index";
import { getuserRegisteredDataFromLocalStorage } from "../../../services/Utils";
import { Link, useNavigate } from "react-router-dom";
import SuccessfullPage from "../successfull-screen";

const OTPPage = () => {
  const [invalidScreen, setInvalidScreen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [accountVerificationloading, setAccountVerificationloading] =
    useState(false);

  const { showErrorToast, showSuccessToast } = useToast();

  const navigate = useNavigate();

  const userRegisterdata = getuserRegisteredDataFromLocalStorage();

  const [OTPverification, { isLoading, isError, isSuccess }] =
    useLazyOTPverificationQuery();

  const [confirmAccount] = useConfirmAccountMutation();

  useEffect(() => {
    const userRegisterdData = localStorage.getItem("userRegisterdData");
    const userHeaderData = localStorage.getItem("userHeaderData");
    const userData = localStorage.getItem("userData");
    if (userHeaderData && userData) {
      setInvalidScreen(true);
      navigate("/greetlisting");
    } else if (!userRegisterdData) {
      setInvalidScreen(true);
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resentOTP = async () => {
    setAccountVerificationloading(true);
    const params = {
      email: userRegisterdata.email,
    };
    try {
      const response = await confirmAccount(params);
      setAccountVerificationloading(false);
      showSuccessToast(response.data.message);
    } catch (err) {
      setAccountVerificationloading(false);
      showErrorToast("OTP not sent!");
    }
  };

  const verfiyOTP = async (values, resetForm) => {
    const params = {
      otp: values.otp_code,
      email: encodeURIComponent(userRegisterdata.email),
    };
    const response = await OTPverification(params);

    if (response.data.data) {
      setSuccessMessage(response.data.message);
      localStorage.removeItem("userRegisterdData");
      resetForm();
    } else {
      setErrorMessage(response.error.data.errors.confirmation_otp[0]);
    }
  };

  return (
    <>
      {invalidScreen ? (
        <BasicLoader />
      ) : (
        <>
          {isSuccess ? (
            <SuccessfullPage successMessage={successMessage} />
          ) : (
            <>
              <Box className="loginPage">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <SideboxUnregistered />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="bg-effect">
                      <Container>
                        <Box className="loginContent">
                          <Box className="content-container">
                            <Typography className="content-title">
                              Hello 👋,
                              <br /> Welcome!
                            </Typography>
                            <Typography className="content-text mt-10">
                              Please use the OTP we've sent to your registered
                              email to log in.
                            </Typography>
                            <Box className="login-form mt-30">
                              <Formik
                                initialValues={initialValuesForOTPScreen}
                                validationSchema={ValidationforOTPpage}
                                onSubmit={(values, { resetForm }) =>
                                  verfiyOTP(values, resetForm)
                                }
                              >
                                {({ values, handleSubmit }) => (
                                  <form onSubmit={handleSubmit}>
                                    <InputField
                                      id="otp_code"
                                      name="otp_code"
                                      label="OTP Code"
                                      value={values.otp_code}
                                    />

                                    {isError && (
                                      <ErrorAlert
                                        errorMessage={
                                          errorMessage
                                            ? errorMessage
                                            : "Invalid OTP or expired."
                                        }
                                      />
                                    )}
                                    <PrimaryButton
                                      title={
                                        isLoading
                                          ? "Verifying..."
                                          : "Verify OTP"
                                      }
                                      styles="submitButton"
                                    />
                                  </form>
                                )}
                              </Formik>
                              <Box className="mt-30 resend-otp-container">
                                <Button
                                  onClick={resentOTP}
                                  className="resendOTP"
                                >
                                  {accountVerificationloading
                                    ? "Resending..."
                                    : "Resend OTP"}
                                </Button>
                              </Box>
                              <Typography className="link-content mt-50">
                                Would you like to log in?{" "}
                                <span>
                                  <Link className="redirect-link" to="/login">
                                    Login
                                  </Link>
                                </span>
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Container>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default OTPPage;
