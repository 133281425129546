import { Box, Container, Stack, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";

import GreetHeader from "../../layout/header/greet-header";
import {
  getHeaderDataFromLocalStorage,
  getUserDataFromLocalStorage,
} from "../../../services/Utils";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import InputField from "../../generic-components/input-field";
import GreetFooter from "../../layout/greet-footer";
import {
  PrimaryButton,
  PrimaryButtonIcon,
} from "../../generic-components/button";
import { ValidationForUpdateProfile } from "../../../schema";
import BasicLoader from "../../Loader/BasicLoader";
import useGetOrganisation from "../../../hooks/useGetOrganisation";
import SharedHeader from "../../generic-components/shared-header";
import ProfileHeader from "../../generic-components/profile-header";
import ProfileImage from "./ProfileImage";
import { baseURL } from "../../../constant";
import axios from "axios";
import PasswordAndSecurity from "../password-security";
import ErrorAlert from "../../generic-components/error-alert";
import SuccessAlert from "../../generic-components/success-alert";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const ProfileSection = () => {
  const userData = getUserDataFromLocalStorage();
  const headerData = getHeaderDataFromLocalStorage();

  const [invalidScreen, setInvalidScreen] = useState(false);
  const [ImagePreview, setImagePreview] = useState(userData?.profile_image);
  const [isLoading, setIsloading] = useState(false);
  const [SuccessText, setSuccessText] = useState(false);
  const [errorState, setErrorState] = useState({
    hasError: false,
    errorMessage: "",
  });

  useEffect(() => {
    const userHeaderData = localStorage.getItem("userHeaderData");
    const userData = localStorage.getItem("userData");
    if (!userHeaderData || !userData) {
      setInvalidScreen(true);
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const { organisation } = useGetOrganisation(userData?.company_id);

  const handleUpdateProfilePicture = (event) => {
    const file = event.target.files[0];

    if (file) {
      setImagePreview(URL.createObjectURL(file));
    } else {
      setImagePreview(userData?.profile_image);
    }
  };

  const handleUpdateProfile = (values) => {
    setIsloading(true);

    const formData = new FormData();
    if (!!values?.profile_image) {
      formData.append("user[profile_image]", values?.profile_image);
    }
    formData.append("user[first_name]", values?.first_name);
    formData.append("user[last_name]", values?.last_name);
    formData.append("user[designation]", values?.designation);
    formData.append("user[employee_number]", values?.employee_number);

    axios
      .patch(
        `${baseURL}/companies/${userData?.company_id}/users/update_profile_details`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            uid: headerData?.uid,
            "access-token": headerData?.access_token,
            client: headerData?.client,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          localStorage.removeItem("userData");
          localStorage.setItem(
            "userData",
            JSON.stringify(response?.data?.data)
          );

          setErrorState({ hasError: false });
          setSuccessText(true);
          setIsloading(false);
          setImagePreview(response?.data?.data?.profile_image);
          navigate("/accounts");
        } else {
          setSuccessText(false);
          setErrorState({
            hasError: true,
            errorMessage: response.data.errors[0],
          });
          setIsloading(false);
        }
      })
      .catch((error) => {
        setSuccessText(false);
        setIsloading(false);
        setErrorState({
          hasError: true,
          errorMessage: "An error occurred while uploading profile!",
        });
      });
  };

  const initialValuesForProfilePage = {
    first_name: userData?.first_name,
    last_name: userData?.last_name,
    employee_number: userData?.employee_number,
    designation: userData?.designation,
    email: userData?.email,
    organisation: organisation?.name,
    profile_image: null,
  };
  const handleBack = () => window.history.back();
  return (
    <>
      {invalidScreen ? (
        <>
          <BasicLoader />
        </>
      ) : (
        <>
          <GreetHeader />
          <Box className="profile-section mb-30">
            <Container>
              <PrimaryButtonIcon
                handleClick={handleBack}
                title="Back"
                styles="Back-btn mt-10"
                startIcon={<ArrowBackIosIcon className="back-icon" />}
                withContent={true}
              />
              <ProfileHeader />
              <Grid container>
                <Grid item xs={12}>
                  {SuccessText && (
                    <SuccessAlert SuccessMessage="Your profile has been successfully updated." />
                  )}
                  {errorState.hasError && (
                    <ErrorAlert errorMessage={errorState.errorMessage} />
                  )}
                  <Box className="profile-section-container mt-18">
                    <SharedHeader title="Update your personal information" />
                    <Box className="profile-container mt-18">
                      <Formik
                        initialValues={initialValuesForProfilePage}
                        validationSchema={ValidationForUpdateProfile}
                        onSubmit={(values) => handleUpdateProfile(values)}
                      >
                        {({ values, handleSubmit }) => (
                          <form onSubmit={handleSubmit}>
                            <Grid container>
                              <Grid item sm={5} md={4} xs={12}>
                                <ProfileImage
                                  handleUpdateProfilePicture={
                                    handleUpdateProfilePicture
                                  }
                                  ImagePreview={ImagePreview}
                                />
                              </Grid>
                              <Grid item sm={7} md={8} xs={12}>
                                <Box className="field-container mt-18">
                                  <Stack>
                                    <InputField
                                      value={values.organisation}
                                      id="company_id"
                                      name="company_id"
                                      label="Organisation"
                                      disabled={true}
                                    />
                                  </Stack>
                                  <Stack
                                    direction={{
                                      xs: "column",
                                      md: "row",
                                    }}
                                    spacing={1}
                                    className="mt-18"
                                  >
                                    <InputField
                                      id="first_name"
                                      name="first_name"
                                      label="First name"
                                      type="text"
                                      value={values.first_name}
                                    />

                                    <InputField
                                      id="last_name"
                                      name="last_name"
                                      label="Last name"
                                      type="text"
                                      value={values.last_name}
                                    />
                                  </Stack>
                                  <Stack className="mt-18">
                                    <InputField
                                      value={values.email}
                                      id="email"
                                      name="eamil"
                                      label="Email"
                                      disabled={true}
                                    />
                                  </Stack>
                                  <Stack className="mt-18">
                                    <InputField
                                      id="employee_number"
                                      name="employee_number"
                                      label="Employee Id"
                                      type="text"
                                      value={values.employee_number}
                                    />
                                  </Stack>
                                  <Stack className="mt-18">
                                    <InputField
                                      id="designation"
                                      name="designation"
                                      label="Designation"
                                      type="text"
                                      value={values.designation}
                                    />
                                  </Stack>
                                </Box>
                                <Box className="update-button">
                                  <PrimaryButton
                                    title={
                                      isLoading
                                        ? "Updating..."
                                        : "Update profile"
                                    }
                                    styles="primarybtn"
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      </Formik>
                    </Box>
                    <PasswordAndSecurity />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <GreetFooter />
        </>
      )}
    </>
  );
};
export default ProfileSection;
