import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SharedHeader from "../../generic-components/shared-header";
import GreetDialog from "../greet-dialog";
import GreetListingLoader from "../../Loader/GreetListingLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { baseURL } from "../../../constant";
import { useDispatch, useSelector } from "react-redux";
import { onUpdate } from "../../redux/reducers/GreetSlice";
import {
  getHeaderDataFromLocalStorage,
  getUserDataFromLocalStorage,
} from "../../../services/Utils";
import gif from "../../../assets/images/animation.gif";

const GreetListing = () => {
  const [greetList, setGreetList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limitReached, setLimitReached] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [greetSend, setGreetSend] = useState(false);

  const userData = getUserDataFromLocalStorage();
  const headerData = getHeaderDataFromLocalStorage();

  const { greetUpdated } = useSelector((state) => state.greeting);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchGreetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (greetUpdated) {
      setTimeout(() => {
        reloadGreetData();
        dispatch(onUpdate(false));
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [greetUpdated]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [greetList]);

  useEffect(() => {
    if (!loading && greetList.length === 0) {
      const errorTimer = setTimeout(() => {
        setShowError(true);
      }, 5000);

      return () => clearTimeout(errorTimer);
    }
  }, [loading, greetList]);

  const reloadGreetData = () => {
    setGreetSend(true);
    setGreetList([]);
    setPage(1);
    if (page === 1 || greetList === []) {
      fetchGreetData();
    }
    setTimeout(() => {
      setGreetSend(false);
    }, 2500);
  };

  const fetchGreetData = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/companies/${userData?.company_id}/greetings?page=${page}`,
        {
          headers: {
            "Content-Type": "application/json",
            uid: headerData.uid,
            "access-token": headerData.access_token,
            client: headerData.client,
          },
        }
      );
      const data = response.data;
      if (data.length < 5) {
        setLimitReached(true);
      }
      setGreetList((prevData) => [...prevData, ...data]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      {greetSend ? (
        <Box className="greeting-animation">
          <Box
            component="img"
            src={gif}
            alt="gif-aniamtion"
            className="gif"
            loading="lazy"
          />
        </Box>
      ) : (
        ""
      )}

      <>
        <SharedHeader title="Greetings" />
        <Box>
          {!greetUpdated ? (
            <>
              {isLoading && <GreetListingLoader />}
              {!isLoading && (
                <>
                  {greetList?.length !== 0 ? (
                    <>
                      {greetList.map((greeting, index) => (
                        <Box key={index}>
                          <GreetDialog greetingInfo={greeting} />
                        </Box>
                      ))}
                      <InfiniteScroll
                        dataLength={greetList?.length}
                        next={fetchMoreData}
                        hasMore={!limitReached}
                        loader={<GreetListingLoader />}
                        endMessage={
                          <Typography
                            className="mt-50"
                            style={{ textAlign: "center", color: "#555555" }}
                          >
                            <b>😃 You've seen everything!</b>
                          </Typography>
                        }
                      />
                    </>
                  ) : (
                    <>
                      {loading && <GreetListingLoader />}
                      {!loading && greetList.length === 0 && !showError && (
                        <GreetListingLoader />
                      )}
                      {!loading && greetList.length === 0 && showError && (
                        <Typography
                          className="mt-50"
                          style={{ textAlign: "center", color: "#555555" }}
                        >
                          <strong>Oops 😟! No data was found...</strong>
                        </Typography>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <GreetListingLoader />
          )}
        </Box>
      </>
    </>
  );
};

export default GreetListing;
