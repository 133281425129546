import { Box } from "@mui/material";
import React from "react";
import smalllogo from "../../assets/logo/small-logo.png";

const FullPageLoader = () => {
  return (
    <Box className="fullpageLoader center">
      <Box className="loader-container center">
        <Box className="spinner center">
          <Box className="logo-container center">
            <Box
              component="img"
              src={smalllogo}
              className="logo"
              alt="loading-logo"
              loading="lazy"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FullPageLoader;
