import { Avatar, Box, Button, Input, InputLabel } from "@mui/material";
import React from "react";
import { getUserDataFromLocalStorage } from "../../../services/Utils";
import UploadIcon from "@mui/icons-material/Upload";
import { useFormikContext } from "formik";

const ProfileImage = ({ handleUpdateProfilePicture, ImagePreview }) => {
  const userData = getUserDataFromLocalStorage();

  const { setFieldValue } = useFormikContext();

  const loggedinUserName = `${userData?.first_name} ${userData?.last_name}`;

  return (
    <>
      <Box className="profile-image mt-18">
        <Box className="sidebox-img-container">
          <Avatar
            alt={loggedinUserName}
            src={ImagePreview}
            className="profileImg"
            loading="lazy"
          />
        </Box>
        <Box component={Button} className=" mt-18">
          <InputLabel className="image-upload" htmlFor="file-input">
            <UploadIcon className="mr-5" />
            Upload profile picture
          </InputLabel>

          <Input
            id="file-input"
            type="file"
            accept="image/*"
            className="file"
            sx={{ display: "none" }}
            onChange={(event) => {
              setFieldValue("profile_image", event.currentTarget.files[0]);
              handleUpdateProfilePicture(event);
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default ProfileImage;
