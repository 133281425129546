import React from "react";
import { Alert } from "@mui/material";

const SuccessAlert = ({ SuccessMessage }) => {
  return (
    <>
      <Alert severity="success" className="mt-18">
        {SuccessMessage}
      </Alert>
    </>
  );
};

export default SuccessAlert;
