
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCOjf4XM4zNTjatuC_wlDzTdsIhqv1GS54",
  authDomain: "clecotechgreeting.firebaseapp.com",
  projectId: "clecotechgreeting",
  storageBucket: "clecotechgreeting.appspot.com",
  messagingSenderId: "654994920257",
  appId: "1:654994920257:web:a91fa244dddb86edc687c0",
  measurementId: "G-RBWL1Y09FL",
};

const firebase = initializeApp(firebaseConfig);

export default firebase;
