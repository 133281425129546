import { Box } from '@mui/material'
import React from 'react'
import PropTypes from "prop-types";

const SharedHeader = ({title}) => {
  return (
    <Box className="sharedHeader">{title}</Box>
  )
}

SharedHeader.propTypes = {
  title: PropTypes.string,
};

export default SharedHeader