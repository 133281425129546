import React from "react";
import { PrimaryButtonURL } from "../../generic-components/button";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PasswordAndSecurity = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Accordion expanded className="password-security-accordion mt-30">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="accordion-heading">
                Password and security
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion-paper">
              <Box>
                <Typography className="changepassword-heading">
                  Would you like to update your password?
                </Typography>
                <Typography className="changepassword-content mt-5">
                  When resetting your password, create one that is unique and
                  includes a combination of uppercase and lowercase letters,
                  numbers, and special characters. Avoid using easily guessable
                  information like your name or birthdate.
                </Typography>

                <PrimaryButtonURL
                  title="Change password"
                  url="/updatePassword"
                  styles="primarybtn mt-30"
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default PasswordAndSecurity;
