import * as yup from "yup";

export const ValidationforAddGreet = yup.object().shape({
  recipient: yup
    .string()
    .required(
      "Please select the recipient you would like to share your greetings with."
    ),
  greet: yup
    .string()
    .required("Please choose a greeting to share with your team mate."),
  greetMessage: yup
    .string()
    .required("Greet message is required. Please provide a friendly greeting!"),
});

export const ValidationforOTPpage = yup.object({
  otp_code: yup
    .string()
    .matches(/^[0-9]+$/, "Please enter a valid OTP Code number.")
    .max(6, "Please enter a valid OTP Code number.")
    .required("Please enter a valid OTP Code number."),
});

export const ValidationforEmail = yup.object({
  email: yup
    .string()
    .email(
      "The email address provided is not in a valid format. Please enter a valid email address."
    )
    .required("Please provide your Email."),
});

export const ValidationforChangePassword = yup.object({
  newpassword: yup
    .string()
    .min(6, "Password must be at least 6 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{6,}$/,
      "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character"
    )
    .required("Password is required"),
  confirm_newpassword: yup
    .string()
    .oneOf(
      [yup.ref("newpassword"), null],
      "Please ensure the entered passwords match"
    )
    .required("Confirm password is required"),
});

export const ValidationforUpdatePassword = yup.object({
  old_password : yup
  .string()
  .required("Password is required"),
  new_password: yup
    .string()
    .min(6, "Password must be at least 6 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{6,}$/,
      "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character"
    )
    .required("Password is required"),
  confirm_newpassword: yup
    .string()
    .oneOf(
      [yup.ref("new_password"), null],
      "Please ensure the entered passwords match"
    )
    .required("Confirm password is required"),
});

export const validationForLogin = yup.object({
  email: yup
    .string()
    .email("Please provide a valid email address to proceed.")
    .required("Please provide your email address to proceed."),
  password: yup
    .string()
    .required("For enhanced security, please provide a password."),
});

export const ValidationforRegistrationPage = yup.object({
  first_name: yup.string().required("Please provide your first name."),
  last_name: yup.string().required("Please provide your last name."),
  email: yup
    .string()
    .email(
      "The email address provided is not in a valid format. Please enter a valid email address."
    )
    .required("Please provide your Email."),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d#@$!%*?&]{6,}$/,
      "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character"
    )
    .required("Password is required"),
  password_confirmation: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Please ensure the entered passwords match"
    )
    .required("Confirm password is required"),
  company_id: yup.string().required("Please provide your Company ID."),
  employee_number: yup.string().required("Please provide your Employee ID."),
  designation: yup.string().required("Please provide your Designation."),
});

export const ValidationForUpdateProfile = yup.object({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  employee_number: yup.string().required("Employee number is required"),
  designation: yup.string().required("Designation is required"),
});
