import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { PrimaryButtonURL } from "../../generic-components/button";

const GreetFooter = () => {
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Box className="footer">
        <Container>
          <Box className="footer-container">
            <Typography className="footer-name">
              © Clecotech International 2023
            </Typography>
            {matches ? (
              ""
            ) : (
              <PrimaryButtonURL
                title="Downlaod"
                url="https://play.google.com/store/apps/details?id=com.cleco.greet&hl=en&gl=US"
                styles="btn rounded-btn"
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default GreetFooter;
