import React from "react";
import { PrimaryButtonURL } from "../../generic-components/button";
import { Container, Box, Grid, Typography } from "@mui/material";
import SideboxUnregistered from "../../internal-components/sidebox-unregistered";


const SuccessfullPage = ({ successMessage }) => {
  return (
    <>
      <Box className="loginPage">
        <Grid container>
          <Grid item xs={12} md={6}>
            <SideboxUnregistered />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="bg-effect">
              <Container>
                <Box className="loginContent successfull-screen">
                  <Box className="content-container">
                    <Typography className="content-title text-center">
                      Hello 👋, <br />
                      {successMessage}
                    </Typography>
                    <Typography className="content-text mt-18 .text-center">
                      Now, you'll be able to login to your account.
                    </Typography>
                    <PrimaryButtonURL url="/login" styles="submitButton mt-30" title="Back to Login" />
                  </Box>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SuccessfullPage;
