import { configureStore } from "@reduxjs/toolkit";
import OrganisationSlice from './components/redux/reducers/OrganisationSlice';
import { GreetAPI } from "./services";
import GreetSlice from "./components/redux/reducers/GreetSlice";



const store = configureStore({
  reducer: { organisation :OrganisationSlice,greeting :GreetSlice, [GreetAPI.reducerPath]: GreetAPI.reducer},
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(GreetAPI.middleware),

});

export default store;
